import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import hiring1 from "../../img/hiring1.gif";
import hiring2 from "../../img/hiring2.gif";
import hiring3 from "../../Videos/hiring3.mp4";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const HiringSection = () => {
    const [startIndex] = useState(0); // State to track the starting index of visible boxes
    const [data, setData] = useState(false);


    const getData = () => {
        axios.get('https://plexus-technology.in/api/admin/read')
            .then((res) => {
                setData(res.data.data[0].hiringStatus);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Failed to fetch data.");
            });
    };


    useEffect(() => {
        getData();
    }, []);

    return (
        <Container className={`pt-5 ${data === "true" ? 'd-block' : 'd-none'}`} id='hiringSection'>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-decoration-underline fw-bold mt-2">HIRING</h3>
            </div>

            <Row className="py-5">
                {/* Display boxes based on startIndex */}
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                    <Col key={index} xs={12} md={6} xl={4} style={{ display: index >= startIndex && index < startIndex + 3 ? "block" : "none" }} className="mt-3 mx-auto">
                        {index === 0 &&
                            <div className="position-relative hiring-box">
                                <img src={hiring1} alt="ul/ux designers" className="img-fluid rounded-4" />
                                <div className="position-absolute h-100 w-100 border top-0 rounded-4" style={{ backgroundColor: "rgba(255,255,255,0.6)" }}></div>
                                <div className="text-white p-3 position-absolute bottom-0 w-100 d-flex rounded-bottom-4 justify-content-between align-items-center" style={{ backgroundColor: "#0777AB" }}>
                                    <p className="m-0">Ui/Ux Designer</p>
                                    <Link onClick={() => window.location.href = "/hiring"} className="text-white text-decoration-none border border-white rounded-pill px-sm-5 px-3 py-1">Apply<FontAwesomeIcon icon={faArrowRight} className="ps-2" /></Link>
                                </div>
                            </div>

                        }
                        {index === 1 &&

                            <div className="position-relative hiring-box">
                                <img src={hiring2} alt="ul/ux designers" className="img-fluid rounded-4" />
                                <div className="position-absolute h-100 w-100 border top-0 rounded-4" style={{ backgroundColor: "rgba(255,255,255,0.6)" }}></div>
                                <div className="text-white p-3 position-absolute bottom-0 w-100 d-flex rounded-bottom-4 justify-content-between align-items-center" style={{ backgroundColor: "#0777AB" }}>
                                    <p className="m-0">Ui/Ux Designer</p>
                                    <Link onClick={() => window.location.href = "/hiring"} className="text-white text-decoration-none border border-white rounded-pill px-sm-5 px-3 py-1">Apply<FontAwesomeIcon icon={faArrowRight} className="ps-2" /></Link>
                                </div>
                            </div>
                        }

                        {index === 2 && (

                            <div className="position-relative hiring-box">
                                <video autoPlay loop style={{ maxWidth: "100%" }} className="rounded-4">
                                    <source src={hiring3} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="position-absolute h-100 w-100 border top-0 rounded-4" style={{ backgroundColor: "rgba(255,255,255,0.6)" }}></div>
                                <div className="text-white p-3 position-absolute bottom-0 w-100 d-flex rounded-bottom-4 justify-content-between align-items-center" style={{ backgroundColor: "#0777AB" }}>
                                    <p className="m-0">Ui/Ux Designer</p>
                                    <Link onClick={() => window.location.href = "/hiring"} className="text-white text-decoration-none border border-white rounded-pill px-sm-5 px-3 py-1">Apply<FontAwesomeIcon icon={faArrowRight} className="ps-2" /></Link>
                                </div>
                            </div>


                        )}
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default HiringSection;
