import { faEnvelope, faPhone, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons/faLocationDot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "reactstrap";


// img
import { faInstagram, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {

    const handleLinkedInClick = () => {
        const linkedinUrl = 'https://www.linkedin.com/company/plexus-technology2024/';

        window.open(linkedinUrl, '_blank');
    };


    const handleWhatsappClick = () => {
        const phoneNumber = '+91 90238 38674';
        const message = 'Hi, I would like to inquire about the services provided by Plexus Technology.';
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };


    const handleInstaClick = () => {
        const instaUrl = 'https://www.instagram.com/plexus_technology/?igsh=MWdkdWZheGxrZjZkZQ%3D%3D';

        window.open(instaUrl, '_blank');
    };

    return (
        <div className="footer-bg mt-5 pb-3">
            <Container className="py-5 px-4">
                <Row className="text-white">
                    <Col md={6} xl={4} className="pt-3 mx-auto">
                        <p className="fs-5"><FontAwesomeIcon icon={faLocationDot} className="text-white pe-2" />Location</p>
                        <p className="fs-6" style={{ fontWeight: "200" }}>305, A.R Mall, opp. panvel point,<br></br> mota varachha, 394101</p>
                    </Col>
                    <Col md={6} xl={2} className="pt-3 mx-auto">
                        <p className="fs-5"><FontAwesomeIcon icon={faPhone} className="text-white pe-2" />Call</p>
                        <p className="fs-6" style={{ fontWeight: "200" }}>+91 90238 38674</p>
                    </Col>
                    <Col md={6} xl={3} className="pt-3 mx-auto">
                        <p className="fs-5"><FontAwesomeIcon icon={faEnvelope} className="text-white pe-2" />Email</p>
                        <a
                            href="mailto:hr.plexustechnology@gmail.com?subject=Job%20Application&body=Dear%20HR,%0D%0A%0D%0AI%20am%20writing%20to%20express%20my%20interest%20in%20the%20available%20position%20at%20Plexus%20Technology.%20Please%20find%20my%20resume%20attached.%0D%0A%0D%0ABest%20regards,%0D%0A[Your%20Name]"
                            className="text-decoration-none fs-6 text-white"
                            style={{ fontWeight: "200" }}
                        >
                            hr.plexustechnology@gmail.com
                        </a>
                    </Col>
                    <Col md={6} xl={3} className="pt-3 mx-auto d-flex flex-column">
                        <p className="fs-5"><FontAwesomeIcon icon={faUserGroup} className="text-white pe-2" />Follow us</p>
                        <div className="d-flex gap-2">
                            <div className="common-footer hover-box box1" onClick={handleLinkedInClick}>
                                <FontAwesomeIcon icon={faLinkedin} className="hover-box2 fs-4" />
                            </div>
                            <div className="common-footer hover-box box2" onClick={handleWhatsappClick}>
                                <FontAwesomeIcon icon={faWhatsapp} className="hover-box2 fs-4" />
                            </div>
                            <div className="common-footer hover-box box3" onClick={handleInstaClick}>
                                <FontAwesomeIcon icon={faInstagram} className="hover-box2 fs-4" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >

    );
}

export default Footer;