import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "reactstrap";

// Import images
import person from "../../img/person.svg";
import innovation from "../../img/innovation.gif";
import quality from "../../img/quality-focused.gif";
import commitment from "../../img/commitment.gif";
import customer from "../../img/customer-first.gif";

const AboutUs = () => {
    // State and refs for each section
    const [activeTab, setActiveTab] = useState('1');
    const [expanded1, setExpanded1] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [expanded3, setExpanded3] = useState(false);
    const [expanded4, setExpanded4] = useState(false);

    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
    const contentRef4 = useRef(null);

    const [overflow1, setOverflow1] = useState(false);
    const [overflow2, setOverflow2] = useState(false);
    const [overflow3, setOverflow3] = useState(false);
    const [overflow4, setOverflow4] = useState(false);

    useEffect(() => {
        // Check overflow for each section on mount
        if (contentRef1.current) {
            setOverflow1(contentRef1.current.scrollHeight > 150);
        }
        if (contentRef2.current) {
            setOverflow2(contentRef2.current.scrollHeight > 150);
        }
        if (contentRef3.current) {
            setOverflow3(contentRef3.current.scrollHeight > 150);
        }
        if (contentRef4.current) {
            setOverflow4(contentRef4.current.scrollHeight > 150);
        }
    }, []);

    const toggle = (num) => {
        setActiveTab(num);
    };

    const handleReadMoreClick = (section) => {
        switch (section) {
            case '1':
                setExpanded1(!expanded1);
                break;
            case '2':
                setExpanded2(!expanded2);
                break;
            case '3':
                setExpanded3(!expanded3);
                break;
            case '4':
                setExpanded4(!expanded4);
                break;
            default:
                break;
        }
    };

    return (
        <Container className="py-5 mb-5 px-3">
            <Row className="d-flex align-items-end">
                <Col lg={5}>
                    <div
                        className={`rounded-4 d-md-flex gap-3 my-3 p-3 pb-4 about2-mainBox ${activeTab === '1' ? 'about2-mainBox-active' : ''}`}
                        onClick={() => { toggle('1'); }}
                        style={{ height: 'main-box-width', position: 'relative', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 3px 0 rgba(0, 0, 0, 0.19)' }}
                    >
                        <div className="gif-box">
                            <img src={innovation} alt="Innovation" className="img-fluid-custom" />
                        </div>
                        <div className="mt-3 px-3 content-container" style={{ height: expanded1 ? '150px' : '150px', overflowY: expanded1 ? 'scroll' : 'hidden', position: 'relative' }}>
                            <h5>Innovation</h5>
                            <p ref={contentRef1} className="content-text">
                                Innovation is at the core of everything we do at Plexus Technology. We constantly explore new ideas and methodologies to create groundbreaking apps that redefine industry standards.
                            </p>
                        </div>
                        {overflow1 && (
                            <button onClick={() => handleReadMoreClick('1')} className={`read-more-btn fw-bold ${activeTab === '1' ? 'text-white' : ''}`}>
                                {expanded1 ? '...READ LESS' : '...READ MORE'}
                            </button>
                        )}
                    </div>

                    <div
                        className={`rounded-4 d-md-flex gap-3 my-3 p-3 pb-4 about2-mainBox ${activeTab === '2' ? 'about2-mainBox-active' : ''}`}
                        onClick={() => { toggle('2'); }}
                        style={{ height: 'main-box-width', position: 'relative', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 3px 0 rgba(0, 0, 0, 0.19)' }}
                    >
                        <div className="gif-box">
                            <img src={commitment} alt="Commitment" className="img-fluid-custom" />
                        </div>
                        <div className="mt-3 px-3 content-container d-flex flex-column justify-content-center" style={{ height: expanded2 ? '150px' : '150px', overflowY: expanded2 ? 'scroll' : 'hidden', position: 'relative' }}>
                            <h5>Developing</h5>
                            <p ref={contentRef2} className="content-text">
                                We develop a user-friendly best app and set new benchmarks in the industry.
                            </p>
                        </div>
                        {overflow2 && (
                            <button onClick={() => handleReadMoreClick('2')} className={`read-more-btn fw-bold ${activeTab === '2' ? 'text-white' : ''}`}>
                                {expanded2 ? '...READ LESS' : '...READ MORE'}
                            </button>
                        )}
                    </div>
                </Col>

                <Col lg={2} className="d-flex align-self-center justify-content-center">
                    <img
                        src={person}
                        alt="person"
                        className="mx-auto img-fluid person-img"
                    />
                </Col>

                <Col lg={5}>
                    <div
                        className={`rounded-4 d-md-flex gap-3 my-3 p-3 pb-4 about2-mainBox ${activeTab === '3' ? 'about2-mainBox-active' : ''}`}
                        onClick={() => { toggle('3'); }}
                        style={{ height: 'main-box-width', position: 'relative', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 3px 0 rgba(0, 0, 0, 0.19)' }}
                    >
                        <div className="gif-box">
                            <img src={quality} alt="Quality-Focused" className="img-fluid-custom" />
                        </div>
                        <div className="mt-3 px-3 content-container d-flex flex-column justify-content-center" style={{ height: expanded3 ? '150px' : '150px', overflowY: expanded3 ? 'scroll' : 'hidden', position: 'relative' }}>
                            <h5>Design</h5>
                            <p ref={contentRef3} className="content-text">
                                We create a user-friendly design from a user's perspective and set new benchmarks in the industry.
                            </p>
                        </div>
                        {overflow3 && (
                            <button onClick={() => handleReadMoreClick('3')} className={`read-more-btn fw-bold ${activeTab === '3' ? 'text-white' : ''}`}>
                                {expanded3 ? '...READ LESS' : '...READ MORE'}
                            </button>
                        )}
                    </div>

                    <div
                        className={`rounded-4 d-md-flex gap-3 my-3 p-3 pb-4 about2-mainBox ${activeTab === '4' ? 'about2-mainBox-active' : ''}`}
                        onClick={() => { toggle('4'); }}
                        style={{ height: 'main-box-width', position: 'relative', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 3px 0 rgba(0, 0, 0, 0.19)' }}
                    >
                        <div className="gif-box">
                            <img src={customer} alt="Customer-First" className="img-fluid-custom" />
                        </div>
                        <div className="mt-3 px-3 content-container" style={{ height: expanded4 ? '150px' : '150px', overflowY: expanded4 ? 'scroll' : 'hidden', position: 'relative' }}>
                            <h5> User-First</h5>
                            <p ref={contentRef4} className="content-text">
                            Our users are at the heart of everything we do. We believe in building products that are not only innovative but also user-centric, ensuring they entertain the user and drive customer success.
                            </p>
                        </div>
                        {overflow4 && (
                            <button onClick={() => handleReadMoreClick('4')} className={`read-more-btn fw-bold ${activeTab === '4' ? 'text-white' : ''}`}>
                                {expanded4 ? '...READ LESS' : '...READ MORE'}
                            </button>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default AboutUs;
