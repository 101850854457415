import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Tabs from './Tabs';


// img
import AboutImg from "../../img/about-us.svg"
import Team from "../../img/team.svg"
import Whyus from "../../img/whysection.svg"

const About = () => {

    return (
        <>
            <div className='about-bg' id='aboutUsSection'>
                <Container>
                    <Row className='d-flex align-items-center px-3 pb-5 mb-3'>
                        <Col lg={6}>
                            <h3 className='text-decoration-underline fw-bold'>ABOUT US</h3>
                            <p className='w-65 py-4'>At plexus technology,<br></br>
                                we are dedicated to shaping ideas converted into innovative product and designed to
                                meet the evolving needs of businesses worldwide. Established in 2023,<br></br>
                                our company has consistently pushed the boundaries of what’s possible,<br></br>
                                combining deep technical expertise with a passion for excellence.</p>
                        </Col>

                        <Col lg={6} className='d-flex'>
                            <img src={AboutImg} alt='About-Us' className='mx-auto img-fluid' />
                        </Col>
                    </Row>



                    <Tabs />


                    <Row className='d-flex align-items-center px-3 pt-5'>
                        <Col lg={5} className='pe-5 order-lg-2 order-1 ms-auto'>
                            <h3 className='text-decoration-underline fw-bold'>Our team</h3>
                            <p className='py-3'>Our team of expert developers, designers, and strategists work collaboratively to create high-quality products tailored to meet the unique needs of our users.</p>
                        </Col>

                        <Col lg={7} className='d-flex order-lg-1 order-2'>
                            <img src={Team} alt='About-Us' className='mx-auto mx-xl-0 img-fluid' />
                        </Col>
                    </Row>

                </Container>
            </div>


            <Container>
                <Row className='d-flex align-items-center px-3 py-5 my-3'>
                    <Col lg={7}>
                        <h3 className='text-decoration-underline fw-bold'>Why plexus technology ?</h3>
                        <p className='w-65 py-3 pe-5'>We are driven by a passion for innovation and excellence. What sets us apart is our commitment to understanding our user's teasing mind and delivering tailored, high-impact entertaining apps & games.</p>
                    </Col>

                    <Col lg={5} className='d-flex'>
                        <img src={Whyus} alt='About-Us' className='mx-auto img-fluid' />
                    </Col>
                </Row>
            </Container>


        </>
    );
}

export default About;
