import React from 'react';


// img
import android from "../../img/language1.svg"
import python from "../../img/language2.svg"
import sq from "../../img/language3.svg"
import react from "../../img/language4.svg"
import node from "../../img/language5.svg"
import swift from "../../img/language6.svg"
import language7 from "../../img/language7.svg"
import ps from "../../img/language8.svg"
import xd from "../../img/language9.svg"
import figma from "../../img/language10.svg"



const Languages = () => {
    return (
        <div className='slider-container py-5 my-4'>
            <div className='slider-content'>
                {/* First set of images */}
                <div className='language'>
                    <img src={android} alt='Android' />
                </div>
                <div className='language'>
                    <img src={python} alt='Python' />
                </div>
                <div className='language'>
                    <img src={sq} alt='SQLite' />
                </div>
                <div className='language'>
                    <img src={react} alt='React' />
                </div>
                <div className='language'>
                    <img src={node} alt='Node.js' />
                </div>
                <div className='language'>
                    <img src={swift} alt='Swift' />
                </div>
                <div className='language'>
                    <img src={language7} alt='Language 7' />
                </div>
                <div className='language'>
                    <img src={ps} alt='Photoshop' />
                </div>
                <div className='language'>
                    <img src={xd} alt='Photoshop' />
                </div>
                <div className='language'>
                    <img src={figma} alt='Figma' />
                </div>
                {/* Repeat the same images */}
                <div className='language'>
                    <img src={android} alt='Android' />
                </div>
                <div className='language'>
                    <img src={python} alt='Python' />
                </div>
                <div className='language'>
                    <img src={sq} alt='SQLite' />
                </div>
                <div className='language'>
                    <img src={react} alt='React' />
                </div>
                <div className='language'>
                    <img src={node} alt='Node.js' />
                </div>
                <div className='language'>
                    <img src={swift} alt='Swift' />
                </div>
                <div className='language'>
                    <img src={language7} alt='Language 7' />
                </div>
                <div className='language'>
                    <img src={ps} alt='Photoshop' />
                </div>
                <div className='language'>
                    <img src={xd} alt='Photoshop' />
                </div>
                <div className='language'>
                    <img src={figma} alt='Figma' />
                </div>
            </div>
        </div>
    );
}


export default Languages;
