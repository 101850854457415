import React, { useState, useEffect } from 'react';
import Loading from '../Component/Loading';
import Header from '../Component/Header';
import HeroSection from '../Component/Home/HeroSection';
import Languages from '../Component/Home/Language';
import About from '../Component/Home/about';
import AboutUs from '../Component/Home/About2';
import Portfolio from '../Component/Home/Portfolio';
import Footer from '../Component/Footer';
import Footer2 from '../Component/Home/footer2';
import HiringSection from '../Component/Home/HiringSection';

const Home = () => {
    const [portfolioData, setPortfolioData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://plexus-technology.in/api/portfolio/read');
                if (!response.ok) {
                    throw new Error('Failed to fetch portfolio data');
                }
                const data = await response.json();
                setPortfolioData(data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Header />
                    <HeroSection />
                    <Languages />
                    <About />
                    <AboutUs />
                    <Portfolio data={portfolioData} />
                    <HiringSection />
                    <Footer2 />
                    <Footer />
        </>
    )
}
        </>
    );
};

export default Home;
