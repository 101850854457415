/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Container, NavbarBrand, Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons/faBarsStaggered';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link as ScrollLink } from "react-scroll";


// img
import logo from '../img/logo.png';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const [data, setData] = useState(false);


    const getData = () => {
        axios.get('https://plexus-technology.in/api/admin/read')
            .then((res) => {
                setData(res.data.data[0].hiringStatus);
            })
            .catch((err) => {
                console.error(err);
                toast.error("Failed to fetch data.");
            });
    };


    useEffect(() => {
        getData();
    }, []);

    return (
        <div id="top">
            <Container>
                <Navbar expand="xl" className="h6-nav-bar pt-4">
                    <NavbarBrand href="/">
                        <img
                            src={logo}
                            alt="Plexus"
                            className="img-fluid d-none d-sm-block"  // Default large screen logo
                            style={{ width: '200px' }}
                        />
                        <img
                            src={logo}
                            alt="Plexus"
                            className="img-fluid d-block d-sm-none" // Small screen logo
                            style={{ width: '150px' }}
                        />
                    </NavbarBrand>
                    <div className='ms-auto'>
                        <Link to={'/hiring'} className={`nav-link px-4 d-none px-4 px-xl-5 merienda textImg ${data === "true" ? 'd-sm-block d-xl-none ' : 'd-none'}`}>We’re Hiring</Link>

                    </div>
                    <NavbarToggler onClick={toggle}><span> <FontAwesomeIcon icon={faBarsStaggered} className='fs-3' /> </span></NavbarToggler>
                    <Collapse isOpen={isOpen} navbar className="font-18" id="h6-info">
                        <Nav navbar className='mx-auto'>
                            <NavItem>
                                <Link className="nav-link px-4 text-blue" to="/">Home</Link>
                            </NavItem>
                            <ScrollLink
                                className="nav-link px-4"
                                to="aboutUsSection"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={200}
                                style={{ cursor: "pointer" }} // Scroll duration in milliseconds
                            >
                                About us
                            </ScrollLink>
                            <ScrollLink
                                className="nav-link px-4"
                                to="portfolioSection"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={200}
                                style={{ cursor: "pointer" }} // Scroll duration in milliseconds
                            >
                                Portfolio
                            </ScrollLink>
                            <ScrollLink
                                className={`nav-link px-4 ${data === "true" ? 'd-block' : 'd-none'}`}
                                to="hiringSection"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={200}
                                style={{ cursor: "pointer" }} // Scroll duration in milliseconds
                            >
                                Hiring
                            </ScrollLink>
                            <NavItem>
                                <Link to={'/hiring'} className={`nav-link ps-4 merienda textImg2  ${data === "true" ? 'd-block d-sm-none ' : 'd-none'}`}>We’re Hiring </Link>
                            </NavItem>
                        </Nav>
                        <div>
                            <Link to={'/hiring'} className={`nav-link px-4 px-4 px-xl-5 merienda textImg ${data === "true" ? 'd-none d-xl-block ' : 'd-none'}`}>We’re Hiring </Link>
                        </div>
                        <div>
                            <Link to="/contact-us" className="btn btn-success-gradiant font-18 px-4 border rounded-pill border-black">Let's Talk <FontAwesomeIcon icon={faArrowRight} className='ps-2' /> </Link>
                        </div>
                    </Collapse>
                </Navbar>
            </Container>
        </div>
    );
}

export default Header;
