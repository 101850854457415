import React, { useState } from 'react';
import { TabContent, TabPane, Nav, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import 'bootstrap/dist/css/bootstrap.min.css';

// img
import vision from "../../img/vision.svg"
import mision from "../../img/mision.svg"
import commitemet from "../../img/commitemet.svg"
import value from "../../img/value.svg"

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>

            <Row className='d-flex align-items-center pb-5 px-3'>
                <Col lg={6} className='pt-3'>
                    <Nav tabs vertical className='border-0'>
                        <div className={classnames('tabs-btn', { 'tabs-btn-click': activeTab === '1' })} onClick={() => { toggle('1'); }} >
                            <div className={`icon-circle m-1 ${activeTab === '1' ? 'icon-circle-onclick' : ''}`} ><img src={vision} alt='vision' className='img-fluid' /></div>
                            <p className='m-0 ps-4 fw-bold '>Our Vision</p>
                        </div>
                        <div className={classnames('tabs-btn', { 'tabs-btn-click': activeTab === '2' })} onClick={() => { toggle('2'); }} >
                            <div className={`icon-circle m-1 ${activeTab === '2' ? 'icon-circle-onclick' : ''}`} ><img src={mision} alt='mision' className='img-fluid' /></div>
                            <p className='m-0 ps-4 fw-bold '>Our mision</p>
                        </div>
                        <div className={classnames('tabs-btn', { 'tabs-btn-click': activeTab === '3' })} onClick={() => { toggle('3'); }} >
                            <div className={`icon-circle m-1 ${activeTab === '3' ? 'icon-circle-onclick' : ''}`} ><img src={commitemet} alt='commitment' className='img-fluid' /></div>
                            <p className='m-0 ps-4 fw-bold '>Our commitment</p>
                        </div>
                        <div className={classnames('tabs-btn', { 'tabs-btn-click': activeTab === '4' })} onClick={() => { toggle('4'); }} >
                            <div className={`icon-circle m-1 ${activeTab === '4' ? 'icon-circle-onclick' : ''}`} ><img src={value} alt='value' className='img-fluid' /></div>
                            <p className='m-0 ps-4 fw-bold '>Core value</p>
                        </div>
                    </Nav>
                </Col>

                <Col lg={6} className='pt-3'>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <div className='px-4'>We focus on completely revolutionizing the mobile application
                                & game industry by adopting a future-forward approach, continually anticipating
                                and adapting to emerging trends, and leveraging the latest technological
                                advancements. We strive to create innovative, user-centric solutions
                                that set new standards of excellence and redefine the possibilities of
                                mobile apps, ensuring that we remain at the forefront of this dynamic and
                                ever-evolving field.
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className='px-4'>Our mission is to deliver transformative IT products that drive business
                                success and elevate user experiences"
                                We are committed to harnessing cutting-edge technology and deep industry
                                expertise to create solutions that solve real-world problems and adapt to
                                the dynamic needs of our clients.
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className='px-4'>Our Commitment At Plexus Technology Company, our commitment Content Change -
                                To our customers - Plexus is committed to entertaining users with
                                impeccable service, improved products, and highly efficient operations.
                                To our employs - We are committed to providing flexible hours ,social events,
                                Celebrate Failures and performance review.
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className='px-3'>
                                <div className='pb-4'>
                                    <p className='m-1'>Our core values shape our identity and actions:</p>
                                </div>

                                <div className='d-xl-flex gap-1 '>
                                    <p className='m-1'><span style={{fontWeight:"600"}}>•Innovation:</span> Embracing creativity and new ideas.</p>
                                </div>
                                <div className='d-xl-flex gap-1 '>
                                    <p className='m-1'><span style={{fontWeight:"600"}}>Customer-Focus:</span> Prioritizing customer needs and satisfaction.</p>
                                </div>
                                <div className='d-xl-flex gap-1 '>
                                    <p className='m-1'><span style={{fontWeight:"600"}}>Teamwork:</span> Valuing collaboration and diverse perspectives.</p>
                                </div>
                                <div className='d-xl-flex gap-1 '>
                                    <p className='m-1'><span style={{fontWeight:"600"}}>Employee-Welfare:</span> Ensuring a healthy and fulfilling workplace.</p>
                                </div>
                                <div className='d-xl-flex gap-1 '>
                                    <p className='m-1'><span style={{fontWeight:"600"}}>Continuous-Learning:</span> Promoting growth and development.</p>
                                </div>
                                <div className='d-xl-flex gap-1 '>
                                    <p className='m-1'><span style={{fontWeight:"600"}}>Social-Responsibility:</span> Committing to positive societal impact.</p>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>


        </div>
    );
}

export default Tabs;

