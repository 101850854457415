import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Counter from './Counter';

// IMG
import playstore from "../../img/playstore.svg";
import appstore from "../../img/appstore.svg";


const Portfolio = ({ data }) => {
    const [activeTab, setActiveTab] = useState('2');
    const [activeButton, setActiveButton] = useState('next');
    const sliderRef = useRef(null);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const handleButtonClick = (direction) => {
        setActiveButton(direction);
        if (direction === 'prev') {
            sliderRef.current.slickPrev();
        } else if (direction === 'next') {
            sliderRef.current.slickNext();
        }
    };

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: data.length > 1,
        autoplaySpeed: 4000,
        dots: false,
    };

    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (e) => {
        const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
        const x = (e.clientX - left - width / 2) / (width / 2);
        const y = (e.clientY - top - height / 2) / (height / 2);
        setMousePosition({ x, y });
    };

    if (!data || data.length === 0) {
        return <div className='d-flex'><Spinner animation="border" className='mx-auto text-blue' /></div>;
    }

    return (
        <Container className='mb-5 overflow-hidden' id='portfolioSection'>
            <div className='d-flex justify-content-between align-items-center'>
                <h3 className='text-decoration-underline fw-bold'>PORTFOLIO</h3>
                {data.length > 1 && (
                    <div className='d-flex gap-3'>
                        <div
                            className={`portfolio-btn ${activeButton === 'prev' ? 'active-btn' : ''}`}
                            onClick={() => handleButtonClick('prev')}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                        <div
                            className={`portfolio-btn ${activeButton === 'next' ? 'active-btn' : ''}`}
                            onClick={() => handleButtonClick('next')}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </div>
                    </div>
                )}

            </div>
            {data.length === 1 ? (
                <div
                    className='overflow-hidden p-0 m-0 border rounded-5 mt-5 border-1 border-black'
                >
                    <div
                        className='rounded-5'
                        onMouseMove={handleMouseMove}
                        style={{ backgroundColor: "#CFCECE" }}
                    >
                        <Row className="h-100 d-flex align-items-center portfolio-bg">
                            <Col lg={6} className='p-5'>
                                <h5 className='fw-bold pb-4'>{data[0].title}</h5>
                                <p className='pe-sm-5'>{data[0].description}</p>
                                <div className='d-flex gap-3 flex-wrap'>
                                    <a href={data[0].playstoreLink} target="_blank" rel="noreferrer"><img src={playstore} alt='playstore' className='img-fluid' /></a>
                                    <a href={data[0].appstoreLink} target="_blank" rel="noreferrer"><img src={appstore} alt='appstore' className='img-fluid' /></a>
                                </div>
                                <div className='pt-5'>
                                    <Row className='d-flex'>
                                        <Col xs={6} sm={4} className='p-1 mx-auto'>
                                            <div className={`border border-secondary rounded-3 text-center pt-4 px-1 my-2  ${activeTab === '1' ? 'bg-blue text-white' : 'bg-light text-black'}`} onClick={() => { toggle('1'); }} style={{ minHeight: "120px" }}>
                                                <h5 className='fw-bold m-0'><Counter end={data[0].playstoreDownloads} duration={500} />M+</h5>
                                                <p className="m-0">Playstore</p>
                                                <p>Downloads</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4} className='p-1 mx-auto'>
                                            <div className={`border border-secondary rounded-3 text-center pt-4 px-1 my-2 ${activeTab === '2' ? 'bg-blue text-white' : 'bg-light text-black'}`} onClick={() => { toggle('2'); }} style={{ minHeight: "120px" }}>
                                                <h5 className='fw-bold m-0'><Counter end={data[0].playstoreDownloads} duration={1000} />M+</h5>
                                                <p className="m-0">Appstore</p>
                                                <p>Downloads</p>
                                            </div>
                                        </Col>
                                        <Col xs={6} sm={4} className='p-1 mx-auto'>
                                            <div className={`border border-secondary rounded-3 text-center pt-4 px-1 my-2 ${activeTab === '3' ? 'bg-blue text-white' : 'bg-light text-black'}`} onClick={() => { toggle('3'); }} style={{ minHeight: "120px" }}>
                                                <h5 className='fw-bold m-0'><Counter end={data[0].country} duration={1000} />million</h5>
                                                <p>Country</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col lg={6} className='d-flex'>
                                <img
                                    src={`https://plexus-technology.in/api/public/images/Portfolio/${data[0].image?.[0]}`}
                                    alt='application'
                                    className='img-fluid mx-auto'
                                    style={{
                                        transform: `translate(${mousePosition.x * 20}px, ${mousePosition.y * 20}px)`,
                                        transition: 'transform 0.2s ease',
                                        height: '630px',
                                        cursor: "pointer"
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            ) : (
                <Slider {...settings} ref={sliderRef}>
                    {data.sort((a, b) => a.rank - b.rank).map((slide, index) => (
                        <div className='px-2 overflow-hidden'>
                            <div
                                className='overflow-hidden p-0 m-0 border rounded-5 mt-5 border-1 border-black'
                            >
                                <div
                                    className='rounded-5'
                                    onMouseMove={handleMouseMove}
                                    style={{ backgroundColor: "#CFCECE" }}
                                >
                                    <Row className="h-100 d-flex align-items-center portfolio-bg">
                                        <Col lg={6} className='p-5'>
                                            <h5 className='fw-bold pb-4'>{slide.title}</h5>
                                            <p className='pe-sm-5'>{slide.description}</p>
                                            <div className='d-flex gap-3 flex-wrap'>
                                                <a href={slide.playstoreLink} target="_blank" rel="noreferrer"><img src={playstore} alt='playstore' className='img-fluid' /></a>
                                                <a href={slide.appstoreLink} target="_blank" rel="noreferrer"><img src={appstore} alt='appstore' className='img-fluid' /></a>
                                            </div>
                                            <div className='pt-5'>
                                                <Row className='d-flex'>
                                                    <Col xs={6} sm={4} className='p-1 mx-auto'>
                                                        <div className={`border border-secondary rounded-3 text-center pt-4 px-1 my-2  ${activeTab === '1' ? 'bg-blue text-white' : 'bg-light text-black'}`} onClick={() => { toggle('1'); }} style={{ minHeight: "120px" }}>
                                                            <h5 className='fw-bold m-0'><Counter end={slide.playstoreDownloads} duration={500} />M+</h5>
                                                            <p className="m-0">Playstore</p>
                                                            <p>Downloads</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} sm={4} className='p-1 mx-auto'>
                                                        <div className={`border border-secondary rounded-3 text-center pt-4 px-1 my-2 ${activeTab === '2' ? 'bg-blue text-white' : 'bg-light text-black'}`} onClick={() => { toggle('2'); }} style={{ minHeight: "120px" }}>
                                                            <h5 className='fw-bold m-0'><Counter end={slide.playstoreDownloads} duration={1000} />M+</h5>
                                                            <p className="m-0">Appstore</p>
                                                            <p>Downloads</p>
                                                        </div>
                                                    </Col>
                                                    <Col xs={6} sm={4} className='p-1 mx-auto'>
                                                        <div className={`border border-secondary rounded-3 text-center pt-4 px-1 my-2 ${activeTab === '3' ? 'bg-blue text-white' : 'bg-light text-black'}`} onClick={() => { toggle('3'); }} style={{ minHeight: "120px" }}>
                                                            <h5 className='fw-bold m-0'><Counter end={slide.country} duration={1000} /> </h5>
                                                            <p>Country</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                        <Col lg={6} className='d-flex'>
                                            <img
                                                src={`https://plexus-technology.in/api/public/images/Portfolio/${slide.image?.[0]}`}
                                                alt='application'
                                                className='img-fluid mx-auto'
                                                style={{
                                                    transform: `translate(${mousePosition.x * 20}px, ${mousePosition.y * 20}px)`,
                                                    transition: 'transform 0.2s ease',
                                                    height: '630px',
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </Container>
    );
};

export default Portfolio;
