import React, { useEffect, useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

// img
import hero1 from "../../img/hero1.svg"
import hero2 from "../../img/hero2.svg"
import hero3 from "../../img/hero3.svg"
import hero4 from "../../img/hero4.svg"
import hero5 from "../../img/hero5.svg"
import hero6 from "../../img/hero6.svg"
import hero7 from "../../img/hero7.svg"
import hero8 from "../../img/hero8.svg"
import hero9 from "../../img/hero9.svg"
import hero10 from "../../img/hero10.svg"
import hero11 from "../../img/hero11.svg"
import hero12 from "../../img/hero12.svg"
import hero13 from "../../img/hero13.svg"
import hero14 from "../../img/hero14.svg"
import hero15 from "../../img/hero15.svg"
// =======================
import app1 from "../../img/app1.svg"
import app2 from "../../img/app2.jpg"
import app3 from "../../img/app3.png"
import app4 from "../../img/app4.png"
import app5 from "../../img/app5.png"
import app6 from "../../img/app6.png"

const HeroSection = () => {


    const [currentDiv, setCurrentDiv] = useState('second'); // Track which div to show

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDiv(prev => (prev === 'first' ? 'second' : 'first'));
        }, 4300); // Toggle every 5 seconds

        // Clean up interval on unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='hero-bgImg text-center d-flex align-items-center justify-content-center flex-column px-3 position-relative'>
            <h1 className='font-50 text-white fw-bold'>We build creative</h1>
            <h1 className='font-50 text-white fw-bold'>Entertainment apps & Games</h1>
            <p className='font-18 py-4 text-white fw-light hero-width'>We transform creative ideas into mobile applications and games to bring a change to our customers’ lives and meet their unique needs.</p>
            <Button className='bg-white border-0 rounded-pill text-black px-5 py-3 arrow-rotate-box' style={{ fontSize: "16px" }}>
                <Link to={'/contact-us'} className='pe-2 text-black text-decoration-none'>Let’s Get connect</Link> <FontAwesomeIcon icon={faArrowRight} className='arrow-rotate' />
            </Button>

            <div>
                <div style={{ display: currentDiv === 'first' ? 'block' : 'none' }}>
                    <div className='hero1 hero-common'>
                        <img src={hero1} alt='lol' className='img-fluid my-5' width={60} />
                    </div>
                    <div className='hero2 hero-common'>
                        <img src={hero2} alt='lol' className='img-fluid my-5' width={60} />
                    </div>
                    <div className='hero3 hero-common'>
                        <img src={hero3} alt='lol' className='img-fluid my-5' width={60} />
                    </div>
                    <div className='hero4 hero-common'>
                        <img src={hero4} alt='lol' className='img-fluid my-5' width={60} />
                    </div>
                    <div className='hero5 hero-common'>
                        <img src={hero5} alt='lol' className='img-fluid my-5' width={60} />
                    </div>
                    <div className='hero6 hero-common'>
                        <img src={hero6} alt='lol' className='img-fluid my-5' width={60} />
                    </div>
                    {/* =========== */}
                    <div className='app1 hero-common hero-common2'>
                        <img src={app1} alt='lol' className='img-fluid my-5 rounded-3' width={50} />
                    </div>
                    <div className='app2 hero-common hero-common2'>
                        <img src={app2} alt='lol' className='img-fluid my-5 rounded-3' width={50} />
                    </div>
                    <div className='app3 hero-common hero-common2'>
                        <img src={app3} alt='lol' className='img-fluid my-5' width={50} />
                    </div>
                    <div className='app4 hero-common hero-common2'>
                        <img src={app4} alt='lol' className='img-fluid my-5' width={45} />
                    </div>
                </div>
                <div style={{ display: currentDiv === 'second' ? 'block' : 'none' }}>
                    <div className='hero7 hero-common'>
                        <img src={hero7} alt='lol' className='img-fluid my-5' width={80} />
                    </div>
                    <div className='hero8 hero-common'>
                        <img src={hero8} alt='lol' className='img-fluid my-5' width={80} />
                    </div>
                    <div className='hero9 hero-common'>
                        <img src={hero9} alt='lol' className='img-fluid my-5' width={80} />
                    </div>
                    <div className='hero10 hero-common'>
                        <img src={hero10} alt='lol' className='img-fluid my-5' width={80} />
                    </div>
                    {/* =========== */}
                    <div className='hero11 hero-common'>
                        <img src={hero11} alt='lol' className='img-fluid my-5' width={70} />
                    </div>
                    <div className='hero12 hero-common'>
                        <img src={hero12} alt='lol' className='img-fluid my-5' width={80} />
                    </div>
                    <div className='hero13 hero-common'>
                        <img src={hero13} alt='lol' className='img-fluid my-5' width={80} />
                    </div>
                    <div className='hero14 hero-common'>
                        <img src={hero14} alt='lol' className='img-fluid my-5' width={70} />
                    </div>
                    <div className='hero15 hero-common'>
                        <img src={hero15} alt='lol' className='img-fluid my-5' width={70} />
                    </div>
                     {/* =========== */}
                     <div className='app5 hero-common hero-common2'>
                        <img src={app5} alt='lol' className='img-fluid my-5 rounded-3' width={150} />
                    </div>
                    <div className='app6 hero-common hero-common2'>
                        <img src={app6} alt='lol' className='img-fluid my-5 rounded-3' width={70} />
                    </div>
                    
                </div>
            </div>

        </div>
    );
}

export default HeroSection;
